<!-- Note that this component is a direct replacement for the old 'DirectionalSwipe' component -->
<script lang="ts" setup>
import type { SwipeDirection } from '~/types/SwipeDirection'
import type { TransitionMode } from '~/types/TransitionMode'

/**
 * VTransitionSlide Props
 *
 * @prop {'left'|'right'|'up'|'down'} direction - direction to slide the element in when entering.
 * @prop {'out-in'|'in-out'} [mode='out-in'] - mode to use (see [Vue Docs]{@link https://vuejs.org/guide/built-ins/transition.html#transition-modes}).
 */
type Props = {
  direction: SwipeDirection
  mode?: TransitionMode
}
type Slots = {
  default(): any
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'out-in',
})
defineSlots<Slots>()

const animationName = computed(() => {
  return `slide-${props.direction}`
})
</script>

<template>
  <Transition :name="animationName" :mode="mode">
    <slot />
  </Transition>
</template>

<!-- Purposefully don't have "scoped" attribute here for the animation styles -->
<style lang="scss">
.slide {
  &-left,
  &-right,
  &-up,
  &-down {
    &-enter-active {
      transition: all 0.15s var(--trans-standard);

      // Only allows fade effect to happen when user has requested reduced motions
      @media (prefers-reduced-motion) {
        transition-property: opacity;
      }
    }

    &-leave-active {
      transition: all 0.1s var(--trans-decelerate);

      // Only allows fade effect to happen when user has requested reduced motions
      @media (prefers-reduced-motion) {
        transition-property: opacity;
      }
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }

  &-left-enter-from,
  &-left-leave-to {
    transform: translateX(12px);
  }

  &-right-enter-from,
  &-right-leave-to {
    transform: translateX(-12px);
  }

  &-up-enter-from,
  &-up-leave-to {
    transform: translateY(12px);
  }

  &-down-enter-from,
  &-down-leave-to {
    transform: translateY(-12px);
  }
}
</style>
